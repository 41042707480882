import(/* webpackMode: "eager", webpackExports: ["ConfigProvider"] */ "__barrel_optimize__?names=ConfigProvider!=!/root/sck-on-fsd/node_modules/antd/es/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["Flex"] */ "__barrel_optimize__?names=Flex!=!/root/sck-on-fsd/node_modules/antd/es/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/root/sck-on-fsd/node_modules/@ant-design/nextjs-registry/es/AntdRegistry.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/root/sck-on-fsd/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/root/sck-on-fsd/node_modules/next-intl/dist/esm/navigation/shared/LegacyBaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/root/sck-on-fsd/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/root/sck-on-fsd/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/root/sck-on-fsd/src/features/select-city/ui/SelectCity.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ProvidersClient"] */ "/root/sck-on-fsd/src/shared/providers/providersClient.tsx");
;
import(/* webpackMode: "eager" */ "/root/sck-on-fsd/src/shared/ui/InDevelopPlug/InDevelopPlug.module.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/root/sck-on-fsd/src/widgets/FooterAboutMobile/ui/FooterAboutMobile.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/root/sck-on-fsd/src/widgets/FooterMobile/ui/FooterMobile.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/root/sck-on-fsd/src/widgets/HeaderMobile/ui/HeaderMobile.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/root/sck-on-fsd/src/widgets/LayoutMain/ui/LayoutMain.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/root/sck-on-fsd/src/widgets/ProductPopularListPagination/ui/ProductPopularListPagination.tsx");
